import UserService from "@/services/UserService";
import { mapActions } from "vuex";
export default {
  mounted() {
    this.loadingItems = true;
    this.getData();
    this.setupSocket();
  },
  unmounted() {},
  created() {},
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },

  data() {
    return {
      chatChannel: "",
      canSendMessage: false,
      canEndChat: false,
      textMessage: "",
      MESSAGE_MAX_LENGTH: 350,
      item: {},
      chatMessages: [],
      canSeeRating: false,
      id: this.$route.params.id,
      loadingItems: false,
      userTypes: UserService.TYPES,
    };
  },
  methods: {
    ...mapActions(["saveUserInfo"]),
    async setupChatBadge() {
      try {
        const res = await this.$chatService.checkForReadMessages();
        this.saveUserInfo({ chatBadge: res.has_unread_messages });
      } catch (e) {
        console.log(e);
      }
    },
    async getData() {
      try {
        const res = await this.$chatService.show(this.id);
        let data = res.data;
        data.pillarObject = this.$missionsService.getMissionPillar(
          data.subject
        );
        data.statusObject = this.$chatService.getChatStatus(data.status);
        this.$set(this, "item", data);

        if (["active"].includes(data.status)) {
          this.canEndChat = true;
          this.canSendMessage = true;
        }
        const resMsg = await this.$chatService.listMessages({
          chat_id: this.id,
          orderBy: [{ sortName: "created_at", order: "ASC" }],
        });
        this.setupChatBadge();
        this.$set(this, "chatMessages", resMsg.data);
        this.scrollDown();
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    handleEndChat() {
      if (!this.canEndChat) return;
      this.$eventBus.$emit("showConfirmationAlert", {
        title: "Deseja encerrar este chat?",
        message: "Não haverá mais interação com este chat. Deseja encerrar?",
        confirmCallback: async () => {
          try {
            await this.$chatService.update(this.id, { status: "concluded" });
            this.$eventBus.$emit("showToast", {
              title: "Chat encerrado",
              msg: `Atendimento concluído`,
            });
            this.getData();
            this.canEndChat = false;
            this.canSendMessage = false;
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async handleSend(image = null) {
      try {
        let msgParams = {};
        if (image) {
          msgParams = { chat_id: this.id, image, type: "image" };
        } else if (this.textMessage) {
          const m = this.textMessage.trim();
          this.textMessage = "";
          msgParams = { chat_id: this.id, message: m, type: "text" };
        }
        await this.$chatService.sendMessage(msgParams);
      } catch (e) {
        console.log(e);
      }
    },
    handleSendImage() {
      document.getElementById("chat-message-input").click();
    },
    cropEnd(image) {
      this.handleSend(image.file);
    },
    async setupSocket() {
      try {
        this.chatChannel = await this.$pusher.subscribe("chat." + this.id);
        this.chatChannel.bind("new-message", (data) => {
          let p = this.chatMessages;
          p.push(data.message);
          this.$set(this, "chatMessages", p);
          this.scrollDown();
        });
      } catch (e) {
        console.log(e);
      }
    },
    scrollDown() {
      setTimeout(() => {
        let container = document.getElementById("main-chat-container");
        if (this.chatMessages.length && container) {
          container.scrollTop = container.scrollHeight;
        }
      }, 100);
    },
  },
};
