import { email, required, sameAs, minLength } from "vuelidate/lib/validators";
import { Modal } from "bootstrap";
export default {
  mounted() {
    if (this.id) {
      this.getData();
    }
    this.copyTimesModal = new Modal(document.getElementById("copyTimesModal"), {
      backdrop: "static",
      keyboard: false,
    });
  },

  computed: {
    copyModalTitle() {
      return this.timesToCopy
        ? `Copiar horários de ${this.timesToCopy.title}`
        : "";
    },
  },
  data() {
    return {
      copyDaysChecked: [],
      timesToCopy: null,
      existingImage: "",
      id: this.$route.params.id,
      recentlyCreatedId: null,
      loadingPic: false,
      DESCRIPTION_MAX_LENGTH: 200,
      errorMessage: "",
      submitting: false,
      specialtiesChecked: [],
      petTypesChecked: [],
      petTypes: [
        { id: "dog", title: "Cachorro" },
        { id: "cat", title: "Gato" },
      ],
      form: {
        image: "",
        name: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        type: "specialist",
        specialist_info: {
          bio: "",
          education: "",
          specialties: "",
          types_pets: "",
        },
        workingHours: [
          {
            weekday: "monday",
            title: "Segunda-feira",
            hours: [],
            isAvailable: false,
          },
          {
            weekday: "tuesday",
            title: "Terça-feira",
            hours: [],
            isAvailable: false,
          },
          {
            weekday: "wednesday",
            title: "Quarta-feira",
            hours: [],
            isAvailable: false,
          },
          {
            weekday: "thursday",
            title: "Quinta-feira",
            hours: [],
            isAvailable: false,
          },
          {
            weekday: "friday",
            title: "Sexta-feira",
            hours: [],
            isAvailable: false,
          },
          {
            weekday: "saturday",
            title: "Sábado",
            hours: [],
            isAvailable: false,
          },
          {
            weekday: "sunday",
            title: "Domingo",
            hours: [],
            isAvailable: false,
          },
        ],
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        image: !this.id
          ? {
              required,
            }
          : "",
        email: {
          required,
          email,
        },
        password: !this.id
          ? {
              required,
            }
          : "",
        passwordConfirmation: !this.id
          ? { required, sameAs: sameAs("password") }
          : "",
        specialist_info: {
          bio: { required },
          education: { required },
          specialties: { required },
          types_pets: { required },
        },
        workingHours: {
          $each: {
            hours: {
              $each: {
                start_time: {
                  // required: requiredIf('show'),
                  required,
                  minLength: minLength(5),
                },
                end_time: {
                  // required: requiredIf('show'),
                  required,
                  valid_date: function (value, sibilings) {
                    return this.$helper.checkDateIsValid(
                      sibilings.start_time,
                      value
                    );
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  methods: {
    async create() {
      try {
        this.submitting = true;
        this.errorMessage = "";
        const values = { ...this.form };
        const image = values.image;
        delete values.passwordConfirmation;
        delete values.image;

        const schedule = this.prepareWorkingHours(values.workingHours);

        delete values.workingHours;
        if (!this.recentlyCreatedId) {
          const res = await this.$userService.create(values);
          if (res.data.id) {
            this.$set(this, "recentlyCreatedId", res.data.id);
          }
        }
        this.$userService.update(this.recentlyCreatedId, {
          roles: [this.$permissionService.ROLES.SPECIALIST],
        });
        await this.$userService.createWorkingHours({
          user_id: this.recentlyCreatedId,
          schedule,
        });
        await this.$userService.updatePicture(this.recentlyCreatedId, {
          image: image.file,
        });
        this.$eventBus.$emit("showToast", {
          title: "Especialista criado",
          msg: `Especialista adicionado com sucesso`,
        });
        this.$router.back();
      } catch (e) {
        console.log(e);
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    prepareWorkingHours(hours) {
      let actualHours = [];
      hours
        .filter((t) => t.hours.length)
        .map((weekDay) => {
          weekDay.hours.map((wh) => {
            actualHours.push({
              weekday: weekDay.weekday,
              title: weekDay.title,
              show: true,
              start_time: wh.start_time,
              end_time: wh.end_time,
            });
          });
        });
      return actualHours;
    },
    async edit() {
      try {
        this.submitting = true;
        this.errorMessage = "";
        const values = { ...this.form };
        const image = values.image;
        delete values.passwordConfirmation;
        delete values.password;
        delete values.image;
        values.roles = values.roles || [
          this.$permissionService.ROLES.SPECIALIST,
        ];
        const schedule = this.prepareWorkingHours(values.workingHours);
        delete values.workingHours;
        await this.$userService.update(this.id, values);
        if (image) {
          await this.$userService.updatePicture(this.id, {
            image: image.file,
          });
        }
        await this.$userService.createWorkingHours({
          user_id: this.id,
          schedule,
        });
        this.$eventBus.$emit("showToast", {
          title: "Especialista alterado",
          msg: `Especialista alterado com sucesso`,
        });
        // this.$router.push({ path: `${this.$paths.specialists.list}` })
        this.$router.back();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    async getData() {
      try {
        const res = await this.$userService.show(this.id);
        const spc = res.data.specialist_info.specialties.split("|");
        this.$set(this, "specialtiesChecked", spc);
        let wHours = [...this.form.workingHours];
        wHours.map((wh) => {
          const h = res.data.working_hours.filter(
            (r) => r.weekday === wh.weekday
          );
          wh.hours = h;
          // console.log(h)
          wh.isAvailable = !!h.length;
        });
        this.$set(this, "form", {
          name: res.data.name,
          email: res.data.email,
          specialist_info: {
            bio: res.data.specialist_info.bio,
            education: res.data.specialist_info.education,
            specialties: res.data.specialist_info.specialties,
          },
          workingHours: wHours,
        });
        this.$set(this, "existingImage", res.data.specialist_info.image);
      } catch (e) {
        console.log(e);
      }
    },
    handleSpecialtiesChanged() {
      const sp = this.specialtiesChecked.join("|");
      this.$set(this.form.specialist_info, "specialties", sp);
    },
    handlePetTypesChanged() {
      const pt = this.petTypesChecked.join("|");
      this.$set(this.form.specialist_info, "types_pets", pt);
      console.log(this.form);
    },
    cropEnd(image) {
      this.$set(this.form, "image", image);
    },
    addTime(weekIndex) {
      let h = this.form.workingHours;
      h[weekIndex].hours.push({
        start_time: "",
        end_time: "",
      });
      h[weekIndex].isAvailable = true;
      this.$set(this.form, "workingHours", h);
    },
    handleCopyTimesClicked(weekIndex) {
      this.timesToCopy = this.form.workingHours[weekIndex];
      this.copyTimesModal.toggle();
    },
    handleChangeAvailability(weekIndex) {
      if (this.form.workingHours[weekIndex].isAvailable) {
        this.form.workingHours[weekIndex].hours = [];
        this.form.workingHours[weekIndex].isAvailable = false;
      } else {
        this.addTime(weekIndex);
      }
      // this.form.workingHours[weekIndex].isAvailable = !this.form.workingHours[weekIndex].isAvailable
    },
    removeTime(weekIndex, hourIndex) {
      let h = this.form.workingHours;
      h[weekIndex].hours.splice(hourIndex, 1);
      if (!h[weekIndex].hours.length) {
        h[weekIndex].isAvailable = false;
      }
      this.$set(this.form, "workingHours", h);
    },
    editImageClicked() {
      document.getElementById("specialist-edit-image-input").click();
    },
    removeImage() {
      this.$set(this.form, "image", "");
    },
    handleCancelCopy() {
      this.copyTimesModal.toggle();
    },
    handleCopy() {
      let w = JSON.parse(JSON.stringify(this.form.workingHours));
      console.log(this.copyDaysChecked);
      w.forEach((element, index) => {
        if (this.copyDaysChecked.includes(element.weekday)) {
          w[index].hours = [...this.timesToCopy.hours];
          w[index].isAvailable = true;
        }
      });

      this.$set(this.form, "workingHours", w);
      this.copyDaysChecked = [];
      this.handleCancelCopy();
    },
  },
};
