<template>
  <div id="app">
    <router-view />
    <NotificationToast />
    <ConfirmationAlert />
  </div>
</template>

<script>
import NotificationToast from "@/components/NotificationToast";
export default {
  name: "App",
  components: {
    NotificationToast,
  },
  mounted() {
    console.log("VERSÃO:", process.env.VUE_APP_VERSION_STRING);
  },
  created() {
    document.title = "Bluu | Backoffice";
  },
};
</script>

<style lang="scss"></style>
