import NetworkService from "@/services/NetworkService";

export default class PetService {
  constructor() {
    this.network = new NetworkService();
  }
  furSizes = [
    {
      id: "short",
      title: "Curta",
    },
    {
      id: "long",
      title: "Longa",
    },
    {
      id: "medium",
      title: "Média",
    },
  ];
  genders = [
    {
      id: "male",
      title: "Macho",
    },
    {
      id: "female",
      title: "Fêmea",
    },
  ];
  sizes = [
    {
      id: "large",
      title: "Grande",
    },
    {
      id: "medium",
      title: "Médio",
    },
    {
      id: "small",
      title: "Pequeno",
    },
  ];
  types = [
    {
      id: "dog",
      title: "Cachorro",
    },
    {
      id: "cat",
      title: "Gato",
    },
  ];
  getPetGender(gender) {
    const defaultObject = {
      title: "",
    };
    return this.genders.find((r) => r.id == gender) || defaultObject;
  }
  getPetFur(fur) {
    const defaultObject = {
      title: "",
    };
    return this.furSizes.find((r) => r.id == fur) || defaultObject;
  }
  getPetSize(size) {
    const defaultObject = {
      title: "",
    };
    return this.sizes.find((r) => r.id == size) || defaultObject;
  }
  list = (filters) => {
    return this.network.get(this.network.endpoints.pets, filters, "");
  };
  listHealthTests = (filters) => {
    return this.network.get(this.network.endpoints.healthTests, filters);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.pets}/${id}`);
  };
}
